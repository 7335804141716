import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import queryString from "query-string";

import "./index.css";
import { BookingStatus, ItemStatus, PaymentStatus } from "../../../types";
import { NoShowTag } from "./components/TagSelect";
import BookingsDataTable from "./components/BookingsDataTable";

function BookingsList() {
  const navigate = useNavigate();
  const location = useLocation();

  const parsedQueryString = queryString.parse(location.search, {
    arrayFormat: "bracket-separator",
    arrayFormatSeparator: "|",
  });

  const pageSize = 10;
  const [page, setPage] = React.useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = React.useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : undefined
  );

  const [from, setFrom] = React.useState<string | null>(
    parsedQueryString.reservationFrom as string
  );

  const [until, setUntil] = React.useState<string | null>(
    parsedQueryString.reservationUntil as string
  );

  const [createdFrom, setCreatedFrom] = React.useState<string | null>(
    parsedQueryString.createdFrom as string
  );

  const [createdUntil, setCreatedUntil] = React.useState<string | null>(
    parsedQueryString.createdUntil as string
  );

  const [orderBy, setOrderBy] = React.useState<string | null>(
    parsedQueryString.orderBy as string
  );

  const [order, setOrder] = React.useState<"ASC" | "DESC" | null>(
    parsedQueryString.order as "ASC" | "DESC"
  );

  const [noShow, setNoShow] = React.useState<NoShowTag | null>(null);

  const [
    bookingStatus,
    setBookingStatus,
  ] = React.useState<BookingStatus | null>(
    parsedQueryString.bookingStatus as BookingStatus
  );
  const [
    paymentStatus,
    setPaymentStatus,
  ] = React.useState<PaymentStatus | null>(null);
  const [itemStatus, setItemStatus] = React.useState<ItemStatus | null>(null);

  React.useEffect(() => {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }, [page, search, navigate]);

  const { loading, error, data, fetchMore } = useQuery<
    GetBookingsResponse,
    GetBookingsInput
  >(GET_BOOKINGS, {
    variables: {
      offset: page * pageSize,
      limit: pageSize,
      search: search,
      noShow: noShow ? true : undefined,
      bookingStatus: bookingStatus ?? undefined,
      paymentStatus: paymentStatus ?? undefined,
      itemStatus: itemStatus ?? undefined,
      reservationFrom: from ?? undefined,
      reservationUntil: until ?? undefined,
      createdFrom: createdFrom ?? undefined,
      createdUntil: createdUntil ?? undefined,
      orderBy: !!orderBy && !!order ? { column: orderBy, order } : undefined,
    },
  });

  if (error) return <>Error! {error.message}</>;

  const rows = data ? data.bookings.records : null;

  return (
    <BookingsDataTable
      parsedQueryString={parsedQueryString}
      loading={loading}
      rows={rows}
      tagFilter={{
        noShow,
        setNoShow,
        itemStatus,
        setItemStatus,
        bookingStatus,
        setBookingStatus,
        paymentStatus,
        setPaymentStatus,
      }}
      dateRangeFilter={{
        from,
        setFrom,
        until,
        setUntil,
      }}
      createdDateRangeFilter={{
        from: createdFrom,
        setFrom: setCreatedFrom,
        until: createdUntil,
        setUntil: setCreatedUntil,
      }}
      search={search}
      setSearch={setSearch}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      pageSize={pageSize}
      totalRows={data ? data.bookings.totalCount : 0}
      fetchMore={fetchMore}
    />
  );
}

const GET_BOOKINGS = gql`
  query BookingsListQuery(
    $offset: Int!
    $limit: Int!
    $search: String
    $noShow: Boolean
    $bookingStatus: BookingStatus
    $paymentStatus: PaymentStatus
    $itemStatus: ItemStatus
    $orderBy: OrderInput
    $reservationFrom: String
    $reservationUntil: String
    $createdFrom: String
    $createdUntil: String
  ) {
    bookings(
      offset: $offset
      limit: $limit
      search: $search
      noShow: $noShow
      bookingStatus: $bookingStatus
      paymentStatus: $paymentStatus
      itemStatus: $itemStatus
      orderBy: $orderBy
      reservationFrom: $reservationFrom
      reservationUntil: $reservationUntil
      createdFrom: $createdFrom
      createdUntil: $createdUntil
    ) {
      records {
        id
        status
        paymentStatus
        familyName
        givenName
        familyNameFurigana
        givenNameFurigana
        email
        reservationDatetime
        createdAt
        noShow
        planSnapshot {
          name
        }
        plan {
          id
          name
          mainVenue {
            name
          }
        }
        productOrders {
          status
          giftmallOrderId
          id
          product {
            id
            templateId
            template {
              id
              displayName
              overrideDisplayName
            }
          }
        }
      }
      totalCount
    }
  }
`;

export type GetBookingsInput = {
  offset: number;
  limit: number;
  search?: string;
  noShow?: boolean;
  bookingStatus?: BookingStatus;
  paymentStatus?: PaymentStatus;
  itemStatus?: ItemStatus;
  reservationFrom?: string;
  reservationUntil?: string;
  createdFrom?: string;
  createdUntil?: string;
  orderBy?: { column: string; order: "ASC" | "DESC" };
};

export type GetBookingsResponse = {
  bookings: {
    records: {
      id: string;
      status: BookingStatus;
      noShow: boolean;
      paymentStatus: PaymentStatus;
      familyName: string;
      givenName: string;
      familyNameFurigana: string;
      givenNameFurigana: string;
      email: string;
      reservationDatetime: string;
      createdAt: string;
      planSnapshot: {
        name: string;
      };
      plan: {
        id: string;
        name: string;
      };
      productOrders: {
        status: string;
      }[];
    }[];
    totalCount: number;
  };
};

export default BookingsList;
